import { Dropdown, Tag, Typography } from "antd";
import { FontAwesomeIcon, Icon, SvgIcon } from "assets/icons";
import { FilterLoadMore } from "components/CustomFilterMenu";
import CustomListFileImage from "components/CustomListFileImage";
import { STATUS_ORDER, STOCK_STATUS } from "config/constants";
import { ORDER_TYPE } from "features/Cart/constants";
import { PAYMENT_STATUS } from "features/Order/constants";
import DeliveryDeadline from "features/Order/Detail/components/DeliveryDeadline";
import { useGetCreatedBy, useGetWarehouses, useOrderPermissions } from "hooks/order";
import { useSalesChannelInputOptions } from "hooks/salesChannel";
import { t } from "i18next";
import { isEqual } from "lodash";
import { SELLER_APPROVAL_STATUS } from "pages/App/Agent/constants";
import {
  renderColorTagByPaymentStatus,
  renderOrderPriority,
  renderPaymentMethod,
  renderRemainingDeliverTime,
  renderShippingMethod,
  renderStatus,
  renderStockStatus,
  renderStringTagByPaymentStatus,
  renderVATAndFullStockRequired,
  tagColorSalesChannel,
} from "pages/App/Order/conts";
import { useOrder } from "pages/App/Order/hooks";
import { useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency } from "utils/helperFuncs";
import FilterCancelReason from "../FilterCancelReason";
import FilterOrder from "../FilterOrder";
import SuccessDeliveryButton from "../SuccessDeliveryButton";

export const inStockOption = [
  { value: STOCK_STATUS.FULL, label: t("common.full") },
  { value: STOCK_STATUS.LACK, label: t("common.lack") },
  { value: STOCK_STATUS.OUT_OF_STOCK, label: t("common.outOfStock") },
];

export const orderTypeOption = [
  { value: true, label: t("Không có ưu đãi") },
  { value: true, label: t("Khuyến mãi 01 sản phẩm") },
  { value: true, label: t("Khuyến mãi combo") },
  { value: true, label: t("Khuyến mãi đơn hàng") },
  { value: true, label: t("Mua chung") },
  { value: true, label: t("Đặt trước") },
  { value: true, label: t("Đấu giá") },
  { value: true, label: t("Ưu đãi kết hợp") },
  { value: true, label: t("Khác") },
];

export const paymentStatusOption = [
  { value: PAYMENT_STATUS.PENDING, label: t("bank_transfer.order.pending") },
  { value: PAYMENT_STATUS.WAITING_APPROVAL, label: t("bank_transfer.order.waiting_approval") },
  { value: PAYMENT_STATUS.PAID, label: t("bank_transfer.order.paid") },
];

const useColumnsOrder = (params, setParams, filterTags, setFilterTags) => {
  const [paramCreatedBy, setParamCreatedBy] = useState({
    pagination: { offset: 0, limit: 10 },
    filters: { query: "" },
  });
  const { data: warehousesOptions } = useGetWarehouses();
  const { data: createdByOptions, fetchMore } = useGetCreatedBy(paramCreatedBy);

  const {
    orderPriorityOptions,
    shippingOptions,
    paymentMethodOptions,
    statusOptions,
    fullStockRequiredOption,
    vatOption,
  } = useOrder();

  const { canCompleteOrder } = useOrderPermissions();

  const { convertedData: dataSalesChannel } = useSalesChannelInputOptions();

  const onFilter = (value) => {
    const newParams = {
      ...params,
      filters: { ...params.filters, ...value },
      pagination: {
        offset: 0,
        limit: 10,
      },
    };
    setParams(newParams);
  };

  const loadMoreDataCreatedBy = async () => {
    await fetchMore({
      variables: {
        pagination: { offset: createdByOptions.length, limit: 10 },
      },
      updateQuery: (pv, { fetchMoreResult }) => {
        const isSamePaginationData = isEqual(
          pv.user.pagination.paginationData,
          fetchMoreResult.user.pagination.paginationData
        );
        if (isSamePaginationData || fetchMoreResult?.user?.pagination?.users.length === 0) return pv;
        const newData = {
          user: {
            pagination: {
              paginationData: { ...fetchMoreResult?.user?.pagination?.paginationData },
              users: [...pv?.user?.pagination?.users, ...fetchMoreResult?.user?.pagination?.users],
            },
          },
        };
        return newData;
      },
    });
  };

  const onSearchTextChange = (values) => {
    setParamCreatedBy({
      ...paramCreatedBy,
      pagination: { offset: 0, limit: 10 },
      filters: { query: values },
    });
  };

  const renderDropdownFilter = (
    options,
    keyObj,
    typeSelection = "checkbox",
    params,
    setParams,
    paginationData = undefined
  ) => {
    return (
      <Dropdown
        overlayClassName="dropdown-overlay"
        trigger="click"
        overlay={
          <FilterOrder
            options={options}
            keyObj={keyObj}
            onFilter={onFilter}
            pagination={paginationData}
            param={params}
            setParam={setParams}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
            typeSelection={typeSelection}
          />
        }
        placement="bottomRight"
      >
        <FontAwesomeIcon icon={Icon.faFilter} />
      </Dropdown>
    );
  };

  const handleSort = (by) => {
    //check sort have orderType is ASC or DESC
    if (params.sort[0]?.orderType === "ASC") {
      const newParams = {
        ...params,
        sort: [{ by: by, orderType: "DESC" }],
      };
      setParams(newParams);
    } else {
      const newParams = {
        ...params,
        sort: [{ by: by, orderType: "ASC" }],
      };
      setParams(newParams);
    }
  };

  const all = {
    index: {
      title: <Typography.Text>{t("order.orderList.index")}</Typography.Text>,
      render: (text, record, index) => index + 1,
      width: 50,
      align: "center",
    },
    orderType: {
      title: (
        <div className="title-table">
          {t("order.orderList.orderType")}
          {renderDropdownFilter(orderTypeOption, "preOrder", "radio")}
        </div>
      ),
      render: (text, record, index) =>
        record.preOrder ? t("order.approvalOrder.preOrder") : t("order.approvalOrder.regularOrder"),
      width: 120,
    },
    createdAt: {
      title: <div className="title-table">{t("order.orderList.createdAt")}</div>,
      dataIndex: "createdAt",
      width: 110,
      sorter: true,
    },
    orderCode: {
      title: <Typography.Text>{t("order.orderList.orderCode")}</Typography.Text>,
      dataIndex: "number",
      render: (text, record) => {
        return (
          <Link to={`/order/${record?.id}`}>
            <b>{text}</b>
          </Link>
        );
      },
      width: 180,
    },
    subTotal: {
      title: (
        <div className="title-table">
          {t("order.orderList.totalOrder")}
          <SvgIcon.SortIcon onClick={() => handleSort("GRAND_TOTAL")} />
        </div>
      ),
      align: "end",
      width: 120,
      render: (text, record) => {
        const totalOrder = record?.orderType !== ORDER_TYPE.CUSTOMER ? record?.total : record.customerTotal;
        return formatCurrency(totalOrder);
      },
    },
    exportedWarehouse: {
      title: (
        <div className="title-table">
          {t("order.orderList.exportWarehouse")}
          {renderDropdownFilter(warehousesOptions, "exportedWarehouseIDs", "checkbox")}
        </div>
      ),
      dataIndex: "exportedWarehouse",
      width: 110,
    },
    receiverName: {
      title: (
        <div className="title-table">
          {t("order.orderList.receiverName")}
          {/* <FontAwesomeIcon icon={Icon.faFilter} /> */}
        </div>
      ),
      dataIndex: "receiverName",
      width: 200,
    },
    orderPriority: {
      title: (
        <div className="title-table">
          {t("order.orderList.orderPriority")}
          {renderDropdownFilter(orderPriorityOptions, "priorities", "checkbox")}
        </div>
      ),
      dataIndex: "orderPriority",
      render: (text) => {
        return renderOrderPriority(text);
      },
      width: 130,
    },
    shippingType: {
      title: (
        <div className="title-table">
          {t("order.orderList.shippingType")}
          {renderDropdownFilter(shippingOptions, "shippingTypes", "checkbox")}
        </div>
      ),
      dataIndex: "shippingType",
      render: (text) => {
        return renderShippingMethod(text);
      },
      width: 200,
    },
    paymentMethod: {
      title: (
        <div className="title-table">
          {t("order.orderList.paymentMethod")}
          {renderDropdownFilter(paymentMethodOptions, "paymentMethods", "checkbox")}
        </div>
      ),
      dataIndex: "paymentMethod",
      render: (text) => {
        return renderPaymentMethod(text);
      },
      width: 150,
    },
    paymentStatus: {
      title: (
        <div className="title-table">
          {t("order.orderList.statusPayment")}
          {renderDropdownFilter(paymentStatusOption, "paymentStatuses", "checkbox")}
        </div>
      ),
      dataIndex: "paymentMethod",
      render: (_, record) => {
        return record?.paymentStatus ? (
          <Tag color={renderColorTagByPaymentStatus(record?.paymentStatus)}>
            {renderStringTagByPaymentStatus(record?.paymentStatus)}
          </Tag>
        ) : (
          "- -"
        );
      },
      width: 200,
    },
    status: {
      title: (
        <div className="title-table">
          {t("order.orderList.status")}
          {renderDropdownFilter(statusOptions, "statuses", "checkbox")}
        </div>
      ),
      dataIndex: "status",
      render: (text) => {
        return renderStatus(text);
      },
      width: 150,
    },
    salesChannel: {
      title: (
        <div className="title-table">
          {t("order.orderDetail.saleChannel")}
          {renderDropdownFilter(dataSalesChannel, "saleChannelIDs", "checkbox")}
        </div>
      ),
      dataIndex: "saleChannel",
      render: (_, record) => {
        return <Tag color={tagColorSalesChannel?.[record?.saleChannel?.type]}>{record?.saleChannel?.name}</Tag>;
      },
      width: 150,
    },
    createdBy: {
      title: (
        <div className="title-table">
          {t("order.orderList.createdBy")}
          <Dropdown
            trigger="click"
            overlay={
              <FilterLoadMore
                onFilter={(values) => onFilter({ createdByIDs: values })}
                data={createdByOptions}
                onLoadMoreData={loadMoreDataCreatedBy}
                onSearchTextChange={onSearchTextChange}
              />
            }
            placement="bottomRight"
          >
            <FontAwesomeIcon icon={Icon.faFilter} />
          </Dropdown>
        </div>
      ),
      dataIndex: "createdBy",
      width: 200,
      // render: (_, record) => {
      //   return record?.createdBy;
      // },
    },
    createdByStatus: {
      title: (
        <div className="title-table">
          {t("order.orderList.createdBy")}
          <Dropdown
            trigger="click"
            overlay={
              <FilterLoadMore
                onFilter={(values) => onFilter({ createdByIDs: values })}
                data={createdByOptions}
                onLoadMoreData={loadMoreDataCreatedBy}
                onSearchTextChange={onSearchTextChange}
              />
            }
            placement="bottomRight"
          >
            <FontAwesomeIcon icon={Icon.faFilter} />
          </Dropdown>
        </div>
      ),
      dataIndex: "createdBy",
      width: 200,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <div>{record?.createdBy}</div>
            {record?.seller?.approvalStatus !== SELLER_APPROVAL_STATUS.APPROVED && (
              <div style={{ fontSize: "12px", color: "#5B6673" }}>(Chờ xác thực)</div>
            )}
          </>
        );
      },
    },
    inStockStatus: {
      title: (
        <div className="title-table">
          {t("order.orderList.inStockStatus")}
          {/* <FontAwesomeIcon icon={Icon.faFilter} /> */}

          {renderDropdownFilter(inStockOption, "stockStatus", "radio")}
        </div>
      ),
      dataIndex: "inStockStatus",
      width: 160,
      render: (text) => renderStockStatus(text),
    },
    fullStockRequired: {
      title: (
        <div className="title-table">
          {t("order.orderList.fullStockRequired")}
          {/* <FontAwesomeIcon icon={Icon.faFilter} /> */}
          {renderDropdownFilter(fullStockRequiredOption, "fullStockRequired", "checkbox")}
        </div>
      ),
      dataIndex: "fullStockRequired",
      width: 150,
      align: "center",
      render: (text) => renderVATAndFullStockRequired(text),
    },
    vat: {
      title: (
        <div className="title-table">
          {t("order.orderList.exportVAT")}
          {/* <FontAwesomeIcon icon={Icon.faFilter} /> */}
          {renderDropdownFilter(vatOption, "vat", "checkbox")}
        </div>
      ),
      width: 120,
      dataIndex: "vat",
      align: "center",
      render: (text) => renderVATAndFullStockRequired(text),
    },
    remainingDeliverTime: {
      title: (
        <div className="title-table">
          {t("order.orderList.remainingDeliverTime")}
          <SvgIcon.SortIcon onClick={() => handleSort("CREATED_AT")} />
        </div>
      ),
      dataIndex: "remainingDeliverTime",
      width: 180,
      align: "center",
      render: (text) => {
        return renderRemainingDeliverTime(text);
      },
    },
    receiverContactAddress: {
      title: <Typography.Text>{t("order.orderList.receiverContactAddress")}</Typography.Text>,
      dataIndex: "receiverContactAddress",
      width: 250,
    },
    deliveryDeadline: {
      title: <Typography.Text>{t("cart.PromisedDeliverTimeLabel")}</Typography.Text>,
      render: (_, record) => <DeliveryDeadline deliveryDeadline={record?.deliveryDeadline} />,
      width: 200,
      align: "left",
      dataIndex: "deliveryDeadline",
      sorter: true,
    },
    shippingUnit: {
      title: <Typography.Text>{t("order.orderList.shippingUnit")}</Typography.Text>,
      dataIndex: "shippingUnit",
      width: 230,
    },
    internalNote: {
      title: <Typography.Text>{t("order.orderList.internalNote")}</Typography.Text>,
      dataIndex: "internalNote",
      width: 150,
    },
    image: {
      title: <Typography.Text>{t("order.orderList.image")}</Typography.Text>,
      dataIndex: "fileURLs",
      width: 150,
      align: "center",
      render: (_, record, index) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomListFileImage listImage={record?.fileURLs} size={80} />
        </div>
      ),
    },
    successfulDeliveryDate: {
      title: <Typography.Text>{t("order.orderList.successfulDeliveryDate")}</Typography.Text>,
      dataIndex: "successfulDeliveryDate",
      width: 150,
    },
    completedAt: {
      title: <Typography.Text>{t("order.orderList.completedAt")}</Typography.Text>,
      dataIndex: "completedAt",
      width: 150,
    },
    shipperTelephone: {
      title: <Typography.Text>{t("order.orderList.shipperTelephone")}</Typography.Text>,
      width: 150,
      dataIndex: "shipperTelephone",
    },
    senderAddress: {
      title: <Typography.Text>{t("order.orderList.senderAddress")}</Typography.Text>,
      render: (record) => <Typography.Text>{record?.receiverContactAddress}</Typography.Text>,
      width: 300,
    },
    noteShipping: {
      title: <Typography.Text>{t("order.orderList.noteShipping")}</Typography.Text>,
      dataIndex: "noteShipping",
      width: 450,
    },
    receiverContactPhone: {
      title: <Typography.Text>{t("order.orderList.receiverContactPhone")}</Typography.Text>,
      dataIndex: "receiverContactPhone",
      width: 150,
    },
    linkShippingSuccess: {
      render: (text, record) => canCompleteOrder && <SuccessDeliveryButton orderID={record.id} />,
      width: 150,
    },
    note: {
      title: <Typography.Text>{t("order.orderList.note")}</Typography.Text>,
      dataIndex: "note",
      width: 150,
    },
    cancelReason: {
      title: (
        <Typography.Text>
          {t("order.orderList.cancelReason")}{" "}
          <FilterCancelReason
            keyObj="cancelReasons"
            onFilter={onFilter}
            params={params}
            setParam={setParams}
            filterTags={filterTags}
            setFilterTags={setFilterTags}
          />
        </Typography.Text>
      ),
      width: 250,
      render: (_, record) => {
        return (
          <Typography.Text>
            {t("order.cancelReasons.cancelBy")}{" "}
            {record?.fullEvents?.[record?.fullEvents?.length - 1]?.createdBy?.type === "SELLER"
              ? t("order.cancelReasons.seller")
              : t("order.cancelReasons.staff")}{" "}
            {t("order.cancelReasons.by")}:
            <br />
            {record?.cancelReason}
          </Typography.Text>
        );
      },
    },
  };

  const {
    index,
    createdAt,
    orderCode,
    subTotal,
    exportedWarehouse,
    receiverName,
    orderPriority,
    shippingType,
    paymentMethod,
    status,
    createdBy,
    inStockStatus,
    fullStockRequired,
    vat,
    remainingDeliverTime,
    receiverContactAddress,
    deliveryDeadline,
    shippingUnit,
    internalNote,
    image,
    successfulDeliveryDate,
    shipperTelephone,
    senderAddress,
    noteShipping,
    receiverContactPhone,
    linkShippingSuccess,
    note,
    cancelReason,
    orderType,
    paymentStatus,
    salesChannel,
    createdByStatus,
  } = all;

  const ALL_COLUMNS_TABLE = [
    index,
    createdAt,
    orderType,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    shippingType,
    paymentMethod,
    paymentStatus,
    status,
    createdByStatus,
    salesChannel,
  ];

  const WAITING_FOR_APPROVAL_ORDER_COLUMNS_TABLE = [
    createdAt,
    orderType,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    inStockStatus,
    vat,
    shippingType,
    paymentMethod,
    note,
    createdByStatus,
    salesChannel,
  ];

  const WAITING_FOR_APPROVAL_COLUMNS_TABLE = [
    createdAt,
    orderType,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    inStockStatus,
    vat,
    shippingType,
    paymentMethod,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_ENOUGH_STOCK_COLUMNS_TABLE = [
    createdAt,
    orderType,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    inStockStatus,
    vat,
    shippingType,
    paymentMethod,
    paymentStatus,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_PAYMENT_COLUMNS_TABLE = [
    createdAt,
    orderType,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    inStockStatus,
    vat,
    shippingType,
    paymentMethod,
    paymentStatus,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_PACKAGING_COLUMNS_TABLE = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    receiverContactAddress,
    shippingType,
    internalNote,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_EXPORTING_COLUMNS_TABLE = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    receiverContactAddress,
    shippingType,
    internalNote,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_ASSEMBLY_COLUMNS_TABLE = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    receiverContactAddress,
    shippingType,
    internalNote,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_SHIPPING_COLUMNS_TABLE_NOT_SET = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    receiverContactPhone,
    senderAddress,
    shippingType,
    paymentMethod,
    paymentStatus,
    internalNote,
    createdBy,
    salesChannel,
  ];

  const WAITING_FOR_SHIPPING_COLUMNS_TABLE_WAITING = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    receiverContactPhone,
    senderAddress,
    shippingType,
    shippingUnit,
    shipperTelephone,
    paymentMethod,
    paymentStatus,
    internalNote,
    createdBy,
    salesChannel,
  ];

  const DELIVERING_COLUMNS_TABLE = [
    createdAt,
    deliveryDeadline,
    orderPriority,
    orderCode,
    receiverName,
    subTotal,
    receiverContactPhone,
    senderAddress,
    shippingType,
    shippingUnit,
    shipperTelephone,
    paymentMethod,
    paymentStatus,
    internalNote,
    createdBy,
    salesChannel,
    linkShippingSuccess,
  ];

  const CANCELED_COLUMNS_TABLE = [
    createdAt,
    orderType,
    orderCode,
    receiverName,
    subTotal,
    exportedWarehouse,
    orderPriority,
    vat,
    shippingType,
    paymentMethod,
    paymentStatus,
    cancelReason,
    createdBy,
    salesChannel,
  ];

  const COMPLETED_COLUMNS_TABLE = [
    index,
    createdAt,
    orderType,
    orderCode,
    subTotal,
    exportedWarehouse,
    receiverName,
    shippingType,
    vat,
    paymentMethod,
    successfulDeliveryDate,
    salesChannel,
    createdBy,
    shippingUnit,
    image,
  ];

  const TABLE_BY_TAB = {
    undefined: ALL_COLUMNS_TABLE,
    [`${STATUS_ORDER.ALL}`]: ALL_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_APPROVAL_ORDER}`]: WAITING_FOR_APPROVAL_ORDER_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_APPROVAL_STOCK}`]: WAITING_FOR_APPROVAL_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_ENOUGH_STOCK}`]: WAITING_FOR_ENOUGH_STOCK_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_PAYMENT}`]: WAITING_FOR_PAYMENT_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_PACKAGING}`]: WAITING_FOR_PACKAGING_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_EXPORTING}`]: WAITING_FOR_EXPORTING_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_ASSEMBLY}`]: WAITING_FOR_ASSEMBLY_COLUMNS_TABLE,
    [`${STATUS_ORDER.WAITING_FOR_SHIPPING_NOT_SET}`]: WAITING_FOR_SHIPPING_COLUMNS_TABLE_NOT_SET,
    [`${STATUS_ORDER.WAITING_FOR_SHIPPING_WAITING}`]: WAITING_FOR_SHIPPING_COLUMNS_TABLE_WAITING,
    [`${STATUS_ORDER.DELIVERING}`]: DELIVERING_COLUMNS_TABLE,
    [`${STATUS_ORDER.COMPLETED}`]: COMPLETED_COLUMNS_TABLE,
    [`${STATUS_ORDER.CANCELED}`]: CANCELED_COLUMNS_TABLE,
  };

  return { TABLE_BY_TAB, renderShippingMethod, renderOrderPriority, renderPaymentMethod };
};

export default useColumnsOrder;
