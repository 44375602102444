/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled } from "@ant-design/icons";
import { Checkbox, ConfigProvider, DatePicker, Form, List, Space, Spin, Typography, message } from "antd";
import viVN from "antd/es/locale/vi_VN";
import SpaceCustom from "components/_storybooks/SpaceCustom";
import FormInput from "components/FormInput";
import { DATE_TIME_FORMAT3 } from "config/constants";
import { useSetDeliveryDeadline } from "hooks/order";
import { t } from "i18next";
import moment from "moment";
import { useState } from "react";
import { formatDateTime } from "utils/dateTime";
import { parseIsoDateStringToMoment } from "utils/helperFuncs";
import WarehouseSelect from "../WarehouseSelect";

function ListReasonApproveOrder({
  loadingApprove,
  orderID,
  chatURL,
  preOrder,
  deliveryDeadline,
  form,
  warehouseName,
  warehouseNameID,
  warehouses,
  dataSource,
  setWarehouseID,
  messages,
  seller,
  warehouseBranch,
}) {
  const [timeSelected, setTimeSelected] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isDatePickerChanging, setIsDatePickerChanging] = useState(false);
  const [warehouseSelected, setWarehouseSelected] = useState({
    label: warehouseName,
    value: warehouseNameID,
    branch: warehouseBranch,
  });
  const { handleSetDeliveryDeadline, loading } = useSetDeliveryDeadline();

  const handleGetWarehouse = (value) => {
    setWarehouseSelected(value);
    setWarehouseID && setWarehouseID(value?.value);
  };

  const renderCheckbox = (value, label, isDisabled) => {
    return (
      <Checkbox value={value} disabled={isDisabled}>
        {label}
      </Checkbox>
    );
  };

  const fnSetDeliveryDeadline = async (deadlineValue) => {
    try {
      await handleSetDeliveryDeadline({
        orderID: orderID,
        input: { deadline: parseIsoDateStringToMoment(deadlineValue) },
      })
        .then(() => {
          setTimeSelected(deadlineValue);
          message.success(t("order.approvalOrder.successChangeTime"));
        })
        .catch((error) => {
          message.error(error.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onChange = (value) => {
    if (isDatePickerChanging) {
      fnSetDeliveryDeadline(value);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleChangeClick = (e) => {
    e.preventDefault();
    setShowDatePicker(true);
    setIsDatePickerChanging(true);
  };

  const handleOpenChange = (open) => {
    if (!open) {
      setIsDatePickerChanging(false);
    }
    setShowDatePicker(open);
  };

  const locale = {
    ...viVN,
    DatePicker: {
      ...viVN.DatePicker,
      lang: {
        ...viVN.DatePicker.lang,
        ok: t("order.approvalOrder.confirmChangeDeliveryDeadline"),
      },
    },
  };

  const warehouseOptions = warehouses?.map(({ id, name, branch }) => ({
    value: id,
    label: name,
    branch: branch,
  }));

  const [isWarehouseSelect, setIsWarehouseSelect] = useState(false);

  const data = [
    {
      title: renderCheckbox(1, "Thông tin nhận hàng"),
      description: `Tài khoản tạo đơn hàng ${seller?.users?.[0].fullname} | ${seller?.users?.[0].email} đã được liên kết với đại lý ${seller?.fullName}.`,
      isHidden: messages?.length,
    },
    {
      title: renderCheckbox(2, "Thông tin về giá"),
      description: "Đơn giá của đơn hàng đã được kiểm tra. Tôi xác nhận bán với giá này",
    },
    // {
    //   title: renderCheckbox(3, "Thời gian giao hàng dự kiến"),
    //   description: (
    //     <Typography.Text>
    //       Thời gian giao hàng dự kiến: <span style={{ color: "#FF9914" }}>10/09/2024 - 20/09/2024</span>.
    //       <br />
    //       Tôi đảm bảo đơn hàng có thể được giao theo như dự kiến.
    //     </Typography.Text>
    //   ),
    // },
    {
      title: renderCheckbox(4, "Hạn chót giao hàng"),
      description: (
        <Typography.Text>
          Hạn chót giao hàng:{" "}
          <Typography.Text type="warning">
            {(timeSelected && formatDateTime(timeSelected, DATE_TIME_FORMAT3)) ||
              (deliveryDeadline && formatDateTime(deliveryDeadline, DATE_TIME_FORMAT3)) ||
              "#"}
          </Typography.Text>
          .{" "}
          <Typography.Link style={{ position: "relative" }} underline onClick={handleChangeClick}>
            Thay đổi hạn chót
            {showDatePicker && (
              <div style={{ position: "absolute", opacity: 0 }}>
                <ConfigProvider locale={locale}>
                  <DatePicker
                    disabledDate={disabledDate}
                    open={showDatePicker}
                    showNow={false}
                    showTime={{
                      format: "HH:mm",
                    }}
                    onOpenChange={handleOpenChange}
                    onChange={onChange}
                    style={{ width: 0, height: 0 }}
                  />
                </ConfigProvider>
              </div>
            )}
          </Typography.Link>
          <br /> Tôi đảm bảo đơn hàng có thể được giao theo yêu cầu của Đại lý.
        </Typography.Text>
      ),
      isHidden: !deliveryDeadline,
    },
    // {
    //   title: renderCheckbox(5, "Tiền đặt cọc"),
    //   description: (
    //     <Typography.Text>
    //       Số tiền đặt cọc <span style={{ color: "#FF9914" }}>5,000,000đ</span> đã về tài khoản. Tôi đã kiểm tra thông
    //       tin với kế toán.
    //     </Typography.Text>
    //   ),
    // },
    {
      title: renderCheckbox(6, "Thông tin tồn kho"),
      description: (
        <Typography.Text>
          Đơn hàng đã có sản phẩm đủ tồn kho. Tôi xác nhận có thể gửi hàng từ kho{" "}
          <span style={{ color: "#FF9914" }}>
            {warehouseSelected?.branch?.name} - {warehouseSelected.label}
          </span>
          .{" "}
          <Typography.Link onClick={() => setIsWarehouseSelect(!isWarehouseSelect)} underline>
            Thay đổi kho xuất
          </Typography.Link>
        </Typography.Text>
      ),
    },
  ];

  return (
    <div style={{ height: isWarehouseSelect ? "600px" : "350px", overflow: "auto" }}>
      <Spin spinning={loading || loadingApprove}>
        <Form form={form} layout="vertical" initialValues={{ options: [] }}>
          <div>
            {messages?.length ? (
              <div>
                {seller.approvalStatus === "WAITING_APPROVAL" && (
                  <SpaceCustom>
                    <Typography.Text type="danger">
                      {messages?.[0]?.content}. Vui lòng
                      <Typography.Link underline target="_blank" href={`/potential-customer-list?statuses=NEW`}>
                        {" "}
                        Duyệt tài khoản!
                      </Typography.Link>
                    </Typography.Text>
                  </SpaceCustom>
                )}
                {seller.approvalStatus === "APPROVED" && (
                  <SpaceCustom>
                    <Typography.Text type="danger">
                      {"Rất tiếc! Không thể duyệt đơn hàng do thông tin đơn hàng không hợp lệ:"}
                    </Typography.Text>
                    <SpaceCustom direction="horizontal" gap={4}>
                      <CloseCircleFilled style={{ color: "red" }} />
                      <Typography.Text>{messages?.[0]?.content}.</Typography.Text>
                    </SpaceCustom>
                    <Typography.Text type="danger">
                      Vui lòng thực hiện
                      <Typography.Link underline target="_blank" href={`/order/${orderID}`}>
                        {" "}
                        Huỷ đơn hàng!
                      </Typography.Link>
                    </Typography.Text>
                  </SpaceCustom>
                )}
              </div>
            ) : (
              <Typography.Text type="warning">
                Vui lòng xác nhận kiểm tra cho tất cả các tiêu chí duyệt đơn hàng!
              </Typography.Text>
            )}
          </div>
          <br />
          <FormInput
            type="CHECK_BOX_GROUP"
            formItemOptions={{
              name: "options",
              validateTrigger: [],
              rules: [
                {
                  required: true,
                  message: "",
                },
                {
                  validator: (rule, value) => {
                    const requiredCount = deliveryDeadline ? 4 : 3;
                    return value.length >= requiredCount
                      ? Promise.resolve()
                      : Promise.reject(new Error("Vui lòng xác nhận kiểm tra cho tất cả các tiêu chí duyệt đơn hàng!"));
                  },
                },
              ],
              style: { margin: 0 },
            }}
            inputChildren={
              <List
                dataSource={data?.filter((item) => !item?.isHidden)}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <SpaceCustom>
                      <Typography.Text strong>{item.title}</Typography.Text>
                      {item.description}
                    </SpaceCustom>
                  </List.Item>
                )}
              />
            }
          />
          <Space direction="vertical" style={{ padding: "8px" }}>
            {isWarehouseSelect && (
              <WarehouseSelect
                options={warehouseOptions}
                defaultValue={warehouseName}
                dataSource={dataSource}
                onGetValue={handleGetWarehouse}
                warehouseSelected={warehouseSelected}
              />
            )}
            <Space>
              <Typography.Text>{t("order.approvalOrder.negotiate")}:</Typography.Text>
              <Typography.Link href={chatURL} target="_blank">
                {t("order.approvalOrder.contactViaChatwoot")}
              </Typography.Link>
            </Space>
          </Space>
        </Form>
      </Spin>
    </div>
  );
}

export default ListReasonApproveOrder;
