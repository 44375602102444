/* eslint-disable no-constant-condition */
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Col, Form, Radio, Row, Select, Space, Spin, Tag, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import CustomModal from "components/CustomModal";
import FormInput from "components/FormInput";
import { PrimaryButtonOutLine } from "components/PrimaryButton";
import { INPUT_TYPE, PRODUCT_TYPE, USER_PERMISSIONS, USER_STATUS } from "config/constants";
import { ORDER_STATUS, SHIPPING_VERSION } from "features/Order/constants";
import {
  isOrderTypeCustomer,
  isShippingTypeBus,
  isShippingTypeDeliver3Party,
  isShippingTypePickUpCounter,
  isShippingTypeUrbanCOD,
  isStockStatusFull,
} from "features/Order/helper";
import { useFlags } from "flagsmith/react";
import { useGetInfoPlatform } from "hooks/info-platform";
import { useConfirmExportOrder } from "hooks/order";
import useDebounce from "hooks/useDebounce";
import { useGetShipper } from "hooks/user/user";
import { t } from "i18next";
import { compact, has } from "lodash";
import { CustomModalApprovePayment } from "pages/App/Order/List/components/CustomModalApprovePayment";
import CustomModalSpiltOrder from "pages/App/Order/List/components/CustomModalSpiltOrder/index ";
import { CANCEL_ORDER_REASON } from "pages/App/Order/conts";
import { useEffect, useState } from "react";
import { notify } from "utils/helperFuncs";
import ListReasonApproveOrder from "../ListReasonApproveOrder";
import { ButtonAssignToCustomer } from "./ButtonAssignToCustomer";
import { ButtonAssignToDeliver } from "./ButtonAssignToDeliver";
import { ButtonChangeShipper } from "./ButtonChangeShipper";
import { ButtonDivisionOrder } from "./ButtonDivisonOrder";
import { ButtonSuccessfulDelivery } from "./ButtonSuccessfulDelivery";

const ButtonApproveOrder = ({
  handle,
  preOrder,
  loadingApprove,
  orderID,
  chatURL,
  deliveryDeadline,
  warehouseName,
  warehouseNameID,
  warehouses,
  dataSource,
  messages,
  seller,
  warehouseBranch,
}) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const [warehouseID, setWarehouseID] = useState();

  const handleOpen = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <CustomModal
      footer={false}
      centered={true}
      customComponent={
        <Button type="primary" onClick={handleOpen}>
          {t("order.approvalOrder.title")}
        </Button>
      }
      onOke={async () =>
        await form
          .validateFields()
          .then(
            async () =>
              await handle({ warehouseID: warehouseID === warehouseNameID ? undefined : warehouseID }).then(handleClose)
          )
      }
      visible={visible}
      onCancel={handleClose}
      title={<Typography.Title level={3}>{t("order.approvalOrder.title")}</Typography.Title>}
      width="70%"
    >
      <ListReasonApproveOrder
        loadingApprove={loadingApprove}
        chatURL={chatURL}
        deliveryDeadline={deliveryDeadline}
        preOrder={preOrder}
        orderID={orderID}
        form={form}
        warehouseName={warehouseName}
        warehouseNameID={warehouseNameID}
        warehouses={warehouses}
        dataSource={dataSource}
        setWarehouseID={setWarehouseID}
        messages={messages}
        seller={seller}
        warehouseBranch={warehouseBranch}
      />
    </CustomModal>
  );
};

const ButtonCancelOrder = ({ handle, items }) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(true);

  const cancelOptions = [
    { label: t("order.cancelOrder.lackOfStock"), value: CANCEL_ORDER_REASON.LACK_OF_STOCK },
    { label: t("order.cancelOrder.outOfDebt"), value: CANCEL_ORDER_REASON.OUT_OF_DEBT },
    { label: "Không nhập được hàng", value: CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER },
    { label: t("order.cancelOrder.cannotBeSoldAtThisPrice"), value: CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE },
    { label: t("bank_transfer.order.overdue_order_payment"), value: CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT },
    {
      label: "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
      value: CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION,
    },
    {
      label: "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng, ...)",
      value: CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER,
    },
    {
      label: "Khách hàng không có nhu cầu mua nữa",
      value: CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY,
    },
    { label: t("order.cancelOrder.other"), value: CANCEL_ORDER_REASON.OTHER },
  ];

  const cancelReason = {
    [`${CANCEL_ORDER_REASON.LACK_OF_STOCK}`]: t("order.cancelOrder.lackOfStock"),
    [`${CANCEL_ORDER_REASON.OUT_OF_DEBT}`]: t("order.cancelOrder.outOfDebt"),
    [`${CANCEL_ORDER_REASON.UNABLE_TO_PRE_ORDER}`]: "Không nhập được hàng",
    [`${CANCEL_ORDER_REASON.CAN_NOT_BE_SOLD_AT_THIS_PRICE}`]: t("order.cancelOrder.cannotBeSoldAtThisPrice"),
    [`${CANCEL_ORDER_REASON.OVERDUE_ORDER_PAYMENT}`]: t("bank_transfer.order.overdue_order_payment"),
    [`${CANCEL_ORDER_REASON.CHANGE_ORDER_INFORMATION}`]:
      "Thay đổi thông tin đơn hàng (Địa chỉ, phương thức vận chuyển, phương thức thanh toán,khuyến mãi,...)",
    [`${CANCEL_ORDER_REASON.CHANGE_PRODUCTS_IN_THE_ORDER}`]:
      "Thay đổi sản phẩm trong đơn hàng (Mã sản phẩm, số lượng,...)",
    [`${CANCEL_ORDER_REASON.CUSTOMERS_NO_LONGER_NEED_TO_BUY}`]: "Khách hàng không có nhu cầu mua nữa",
  };

  const handleCancelOrder = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const onSubmit = async () =>
    await form
      .validateFields()
      .then(
        async (values) =>
          await handle(values.reason === CANCEL_ORDER_REASON.OTHER ? values.freeText : cancelReason[`${values.reason}`])
      );

  const handleValuesChange = (changedValues, allValues) => {
    const formValues = form.getFieldsValue();
    if (has(changedValues, "reason")) {
      if (changedValues.reason === "OTHER") {
        setHidden(false);
      } else {
        setHidden(true);
        form.setFieldsValue({ ...formValues, freeText: "" });
      }
    }
  };

  const warningMessage = () => {
    const isKeyLicense = items?.some((item) => item?.product?.productType !== "MATERIAL");

    if (isKeyLicense) {
      return `Lưu ý: Đơn hàng của bạn chứa 02 loại sản phẩm: key license/ account và sản phẩm thông thường. Nếu bạn Xác nhận hủy đơn, hệ thống sẽ hủy toàn bộ đơn hàng!`;
    }
    return `Nếu bạn Xác nhận hủy đơn, trạng thái của đơn hàng sẽ chuyển sang Đã hủy!`;
  };

  return (
    <CustomModal
      footer={false}
      centered={true}
      title={<Typography.Text strong>{t("order.cancelOrder.tittle")}</Typography.Text>}
      customComponent={<PrimaryButtonOutLine title={t("order.orderDetail.cancelBtn")} onClick={handleCancelOrder} />}
      onOke={onSubmit}
      visible={visible}
      onCancel={closeModal}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Typography.Text type="warning">{warningMessage()}</Typography.Text>{" "}
          </Space>
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Typography.Text type="warning">
              Lưu ý: Đơn hàng này thuộc nhóm đặt trước đã được duyệt thành lập. Sau khi hủy đơn thành công, hệ thống sẽ
              tiếp tục xử lý nhóm đặt trước mà không thay đổi đơn giá hoặc khả năng cung ứng.
            </Typography.Text>
          </Space>
          <Space style={{ width: "100%", justifyContent: "center" }}>
            <Typography.Text type="warning">
              Lưu ý: Đơn hàng này thuộc nhóm đặt trước [trạng thái hiện tại của nhóm đặt trước]. Sau khi hủy đơn thành
              công, hệ thống sẽ cập nhật lại thông tin về tiến độ đặt hàng của nhóm đặt trước.
            </Typography.Text>
          </Space>
        </Col>
        <Col span={24}>
          <Form form={form} onValuesChange={handleValuesChange}>
            <FormInput
              type={INPUT_TYPE.RADIO_GROUP}
              formItemOptions={{
                name: "reason",
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("reason")) {
                        return Promise.resolve();
                      }
                      return Promise.reject(notify.warning({ message: t("order.cancelOrder.warningMessage") }));
                    },
                  }),
                ],
                style: { marginBottom: 0 },
              }}
              inputChildren={
                <Space direction="vertical">
                  {cancelOptions.map((item, index) => (
                    <Radio key={item.value} value={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              }
            />
            <FormInput
              type={INPUT_TYPE.TEXT_AREA}
              formItemOptions={{
                name: "freeText",
                hidden,
                rules: [
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("reason") !== "OTHER") {
                        return Promise.resolve();
                      }
                      if (getFieldValue("reason") === "OTHER" && value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("order.cancelOrder.otherFieldCannotEmpty")));
                    },
                  }),
                ],
                style: { marginBottom: 0 },
              }}
              inputOptions={{ placeholder: t("order.cancelOrder.otherPlaceholder") }}
            />
          </Form>
        </Col>
      </Row>
    </CustomModal>
  );
};

const ButtonEditOrder = ({ handle }) => {
  const flags = useFlags(["is_hide_edit_order"]);
  const isHideEditOrder = !flags.is_hide_edit_order.enabled;
  return (
    isHideEditOrder || (
      <Button onClick={handle}>
        <Typography.Text strong>{t("order.orderDetail.editBtn")}</Typography.Text>
      </Button>
    )
  );
};

const ButtonWaitingForEnoughStock = ({ handle, stockStatus }) => {
  return isStockStatusFull(stockStatus) ? (
    <CustomModal
      footer={false}
      centered={true}
      customComponent={<Button>{t("order.orderStatus.waitingForEnoughStock")}</Button>}
      onOke={handle}
    >
      <Row gutter={[16, 8]}>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <ExclamationCircleFilled style={{ fontSize: "48px", color: "#ff7f00" }} />
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Text strong>{t("order.changeOrderStatusWarning.waitingStockDetail")}</Typography.Text>
        </Col>
      </Row>
    </CustomModal>
  ) : (
    <Button onClick={handle}>
      <Typography.Text strong>{t("order.orderStatus.waitingForEnoughStock")}</Typography.Text>
    </Button>
  );
};

const ButtonApproveStock = ({ handle }) => {
  return (
    <Button onClick={handle} type="primary">
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderDetail.approveBtn")}
      </Typography.Text>
    </Button>
  );
};

const ButtonUnableToImport = ({ handle, stockStatus }) => {
  return isStockStatusFull(stockStatus) ? (
    <CustomModal
      footer={false}
      centered={true}
      customComponent={<Button>{t("order.orderDetail.unableToImport")}</Button>}
      onOke={handle}
    >
      <Row gutter={[16, 8]}>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <ExclamationCircleFilled style={{ fontSize: "48px", color: "#ff7f00" }} />
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Text strong>{t("order.changeOrderStatusWarning.denyStockDetail")}</Typography.Text>
        </Col>
      </Row>
    </CustomModal>
  ) : (
    <Button onClick={handle}>
      <Typography.Text strong>{t("order.orderDetail.unableToImport")}</Typography.Text>
    </Button>
  );
};

const ButtonSufficientConfirmation = ({ handle }) => {
  return (
    <Button onClick={handle} type="primary">
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderDetail.sufficientConfirmation")}
      </Typography.Text>
    </Button>
  );
};

const ButtonApprovePayment = ({ handle }) => {
  return (
    <CustomModal
      footer={false}
      centered={true}
      customComponent={<Button type="primary">{t("order.orderDetail.approvedPayment")}</Button>}
      onOke={handle}
    >
      <Row gutter={[16, 8]}>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <ExclamationCircleFilled style={{ fontSize: "48px", color: "#ff7f00" }} />
        </Col>
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Text strong>{t("order.orderList.confirmPaymentWarning")}</Typography.Text>
        </Col>
      </Row>
    </CustomModal>
  );
};

const ButtonPrintAndPack = ({ onPrint, handle, order }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const keyLicenseProducts = order?.items?.filter(
    (item) => item.product.productType === PRODUCT_TYPE.KEY_LICENSE || item.product.productType === PRODUCT_TYPE.ACCOUNT
  );

  const handleCancel = () => setIsVisible(false);

  const handleOk = async () => {
    try {
      setLoading(true);
      await onPrint()
        .then(async () => {
          notify.success({ message: "In đơn thành công" });
          await handle()
            .then(() => {
              // notify.success({ message: "Đóng gói thành công" });
              setLoading(false);
              handleCancel();
            })
            .catch((error) => {
              setLoading(false);
              notify.error({ message: error.message });
            });
        })
        .catch((error) => {
          setLoading(false);
          notify.error({ message: error.message });
        });
    } catch (error) {
      console.info(error);
    }
  };

  return (
    <>
      <Button onClick={() => setIsVisible(true)} type="primary" loading={loading}>
        {t("order.orderDetail.printAndPackage")}
      </Button>
      <CustomModal
        footer={false}
        centered={true}
        visible={isVisible}
        closable={false}
        buttonLoading={loading}
        selfVisibleControlled={false}
        customIcon={<ExclamationCircleFilled style={{ color: "rgba(239, 147, 81, 1)", fontSize: "40px" }} />}
        title={
          <Typography.Text strong style={{ fontSize: "16px" }}>
            {t("order.packagingOrder.title")}
          </Typography.Text>
        }
        message={
          <Typography.Text type="warning">
            {keyLicenseProducts?.length
              ? `Lưu ý: Đơn hàng có chứa ${keyLicenseProducts?.length} sản phẩm key license/ account nên một phần đơn hàng sẽ được chuyển sang bộ phận kho Digital xử lý!`
              : `Lưu ý: Sau khi Xác nhận, đơn hàng sẽ được chuyển sang trạng thái “Chờ xuất kho”`}
          </Typography.Text>
        }
        onCancel={handleCancel}
        onOke={handleOk}
      />
    </>
  );
};

const ButtonApproveExport = ({ handle, order, canSuggestKeyLicenseAccountConfirm, canExportStock }) => {
  const queryParams = new URLSearchParams();
  queryParams.set("orderId", JSON.stringify(order?.id));
  const { handleConfirmExportOrder } = useConfirmExportOrder();
  const { platformCode } = useGetInfoPlatform();

  const flags = useFlags(["is_redirect_export_serial"]);
  const listPlatformCode = JSON.parse(flags.is_redirect_export_serial?.value)?.platformCode;

  const handleExportConfirm = async () => {
    await handleConfirmExportOrder({
      orderIDs: [order?.id],
    }).then(() => {
      notify.success({
        message: t("order.orderList.exportingSuccess"),
      });
    });
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "checkReload") {
        window.location.reload();
        localStorage.removeItem("checkReload");
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    var retrievedUser = JSON.parse(localStorage.getItem("checkReload"));
    if (retrievedUser) {
      localStorage.removeItem("checkReload");
      window.location.reload();
    }
  }, []);

  const hasDigitalProduct = order?.items?.some(
    (item) =>
      item?.product?.productType === PRODUCT_TYPE.KEY_LICENSE || item?.product?.productType === PRODUCT_TYPE.ACCOUNT
  );

  const handleClick = () => {
    if (hasDigitalProduct) {
      if (canSuggestKeyLicenseAccountConfirm) {
        const url = `/create-export-key#${order?.id}`;
        window.open(url, "_blank");
      } else {
        notify.warning({
          message: t("order.orderDetail.warning_export_product_digital"),
        });
      }
    } else {
      if (canExportStock) {
        const url = `/export-serial/create?${queryParams.toString()}`;
        window.open(url, "_blank");
      } else {
        notify.warning({
          message: t("order.orderDetail.warning_export_product_material"),
        });
      }
    }
  };

  return listPlatformCode?.includes(platformCode) ? (
    <Button type="primary" onClick={handleClick}>
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderDetail.exportConfirmation")}
      </Typography.Text>
    </Button>
  ) : (
    <Button type="primary" onClick={handleExportConfirm}>
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderDetail.exportConfirmation")}
      </Typography.Text>
    </Button>
  );
};

const ButtonApproveAssembly = ({ handle }) => {
  return (
    <Button onClick={handle} type="primary">
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderList.confirmAssemble")}
      </Typography.Text>
    </Button>
  );
};

const ButtonAssignToShipper = ({ handle }) => {
  const [form] = Form.useForm();
  const [searchTerm, setSearchTerm] = useState("");

  const [params, setParams] = useState({
    filters: {
      query: undefined,
      permission: USER_PERMISSIONS.ORDER_SHIPPER_DELIVER,
      status: USER_STATUS.ACTIVE,
    },
    pagination: {
      offset: 0,
      limit: 10,
    },
  });

  const { data, loading: loadingGetShipper } = useGetShipper(params);
  const debounceValue = useDebounce(searchTerm);

  const renderTag = ({ totalWaiting, totalDelivering }) => {
    // if(totalWaiting>0)
    if (totalWaiting && totalDelivering) {
      return (
        <Space>
          <Tag color="orange">
            {t("order.orderList.orderWaitAssigned")} {totalWaiting}
          </Tag>
          <Tag color="red">
            {t("order.orderList.orderAssigned")} {totalDelivering}
          </Tag>
        </Space>
      );
    }
    if (totalWaiting) {
      return (
        <Tag color="orange">
          {t("order.orderList.orderWaitAssigned")} {totalWaiting}
        </Tag>
      );
    }
    if (totalDelivering) {
      return (
        <Tag color="red">
          {t("order.orderList.orderAssigned")} {totalDelivering}
        </Tag>
      );
    }
    return <Tag color="green">{t("order.orderList.orderNotAssigned")}</Tag>;
  };

  useEffect(() => {
    setParams({
      ...params,
      filters: {
        ...params.filters,
        query: debounceValue,
      },
      pagination: {
        limit: 10,
        offset: 0,
      },
    });
  }, [debounceValue]);

  const onSubmit = async () => {
    const formValues = form.getFieldsValue();
    await handle(formValues?.shipperID);
  };

  return (
    <CustomModal
      title={<Typography.Text strong>{t("order.selectingShipper.title")}</Typography.Text>}
      footer={false}
      centered={true}
      customComponent={
        <Button style={{ width: "100%" }} type="primary">
          {t("order.orderList.confirmShipping")}
        </Button>
      }
      onOke={onSubmit}
    >
      <Form form={form}>
        <Form.Item
          label={<Typography.Text strong>{t("order.selectingShipper.shipperLabel")}</Typography.Text>}
          name="shipperID"
          rules={[
            {
              required: true,
              message: t("order.selectingShipper.shipperValidateMessage"),
            },
          ]}
        >
          <Select
            showSearch
            placeholder={t("order.selectingShipper.shipperPlaceholder")}
            filterOption={false}
            optionLabelProp="label"
            onSearch={(value) => setSearchTerm(value)}
            dropdownRender={(originNode) => <Spin spinning={loadingGetShipper}>{originNode}</Spin>}
          >
            {data?.map(({ id, fullname, totalOrderDelivery, telephone }) => (
              <Select.Option key={id} value={id} label={fullname}>
                <Space style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography.Text>{compact([fullname, telephone]).join(" - ")}</Typography.Text>
                  {renderTag(totalOrderDelivery)}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

const ButtonRecreateOrder = ({ handle }) => {
  return (
    <Button onClick={handle} type="primary">
      <Typography.Text strong style={{ color: "#ffffff" }}>
        {t("order.orderDetail.recreateCart")}
      </Typography.Text>
    </Button>
  );
};

const ButtonGroup = ({ dataButtonGroup, actions, order }) => {
  const {
    onCancelOrder,
    onWaitingForEnoughStock,
    onDeny,
    onApproveStock,
    onExportOrderWithPrint,
    onPackage,
    onExportConfirm,
    onAssemblyConfirm,
    onConfirmShipper,
    onConfirmCustomer,
    onRecreateOrder,
    onEditOrder,
    onApproveOrder,
    onChangeShipper,
  } = actions;
  const {
    status,
    preOrder,
    stockStatus,
    shippingType,
    canApproveStock,
    canAssignShipper,
    canCancelOrder,
    canCompleteAssembly,
    canApproveOrder,
    canCompleteOrder,
    canConfirmPayment,
    canDenyStock,
    canExportStock,
    canRecreateCart,
    canWaitStock,
    id,
    exportedWarehouse,
    number,
    serviceFee,
    discount,
    shippingFee,
    paymentMethod,
    items,
    orderType,
    canOrderCancelBeforePayView,
    canOrderCancelAfterPayView,
    shippingStatus,
    shippingVersion,
    chatURL,
    deliveryDeadline,
    loadingApproveOrder,
    canSuggestKeyLicenseAccountConfirm,
    canSuggestKeyLicenseAccountCancel,
    seller,
    messages,
  } = dataButtonGroup;

  const renderButtonGroupByStatus = {
    [`${ORDER_STATUS.WAITING_FOR_APPROVAL_ORDER}`]: (
      <>
        {canCancelOrder && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canApproveOrder && (
          <ButtonApproveOrder
            handle={onApproveOrder}
            preOrder={preOrder}
            chatURL={chatURL}
            deliveryDeadline={deliveryDeadline}
            loadingApprove={loadingApproveOrder}
            orderID={id}
            warehouseName={exportedWarehouse?.name}
            warehouseNameID={exportedWarehouse?.id}
            warehouses={seller?.warehouses}
            dataSource={items}
            messages={messages}
            seller={seller}
            warehouseBranch={exportedWarehouse.branch}
          />
        )}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_APPROVAL_STOCK}`]: (
      <>
        {canOrderCancelBeforePayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canWaitStock && <ButtonWaitingForEnoughStock handle={onWaitingForEnoughStock} stockStatus={stockStatus} />}
        {isStockStatusFull(stockStatus) ? (
          canApproveStock && <ButtonApproveStock handle={onApproveStock} />
        ) : (
          <CustomModalSpiltOrder
            items={items}
            labelButton={t("order.splitOrder.confirmAndApprovedEnoughStock")}
            infoOrder={{
              id,
              nameStock: exportedWarehouse?.name,
              orderNumber: number,
              serviceFee: serviceFee,
              discount: discount,
              shippingFee: shippingFee,
              paymentMethod: paymentMethod,
            }}
            isHidden={!isOrderTypeCustomer(orderType)}
          />
        )}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_ENOUGH_STOCK}`]: (
      <>
        {canOrderCancelBeforePayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canDenyStock && <ButtonUnableToImport handle={onDeny} stockStatus={stockStatus} />}
        {canApproveStock && <ButtonSufficientConfirmation handle={onApproveStock} />}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_PAYMENT}`]: (
      <>
        {canOrderCancelBeforePayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canConfirmPayment && <CustomModalApprovePayment orderIDs={[order?.id]} orderDetail={order} />}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_PACKAGING}`]: (
      <>
        {canOrderCancelAfterPayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        <ButtonPrintAndPack onPrint={onExportOrderWithPrint} handle={onPackage} order={order} />
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_EXPORTING}`]: (
      <>
        {(canOrderCancelAfterPayView || canSuggestKeyLicenseAccountCancel) && (
          <ButtonCancelOrder items={items} handle={onCancelOrder} />
        )}
        <ButtonEditOrder handle={onEditOrder} />
        {(canExportStock || canSuggestKeyLicenseAccountConfirm) && (
          <ButtonApproveExport
            handle={onExportConfirm}
            order={order}
            canSuggestKeyLicenseAccountConfirm={canSuggestKeyLicenseAccountConfirm}
            canExportStock={canExportStock}
          />
        )}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_ASSEMBLY}`]: (
      <>
        {canOrderCancelAfterPayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canCompleteAssembly && <ButtonApproveAssembly handle={onAssemblyConfirm} />}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_SHIPPING}`]: (
      <>
        {canOrderCancelAfterPayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {isShippingTypePickUpCounter(shippingType) && (
          <ButtonAssignToCustomer handle={onConfirmCustomer} orderDetail={order} />
        )}
        {isShippingTypeDeliver3Party(shippingType) && shippingVersion === SHIPPING_VERSION.V1 && (
          <ButtonAssignToDeliver orderDetail={order} />
        )}
        {(isShippingTypeUrbanCOD(shippingType) || isShippingTypeBus(shippingType)) && canAssignShipper && (
          <ButtonAssignToShipper handle={onConfirmShipper} />
        )}
        {(isShippingTypeBus(shippingType) || isShippingTypeDeliver3Party(shippingType)) &&
          shippingVersion === SHIPPING_VERSION.V2 && <ButtonDivisionOrder order={order} />}
      </>
    ),
    [`${ORDER_STATUS.WAITING_FOR_SHIPPING + "WAITING"}`]: (
      <>
        {canOrderCancelAfterPayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {isShippingTypePickUpCounter(shippingType) && (
          <ButtonAssignToCustomer handle={onConfirmCustomer} orderDetail={order} />
        )}
        {(isShippingTypeBus(shippingType) || isShippingTypeDeliver3Party(shippingType)) && canAssignShipper && (
          <ButtonChangeShipper order={order} handle={onChangeShipper} />
        )}
        {isShippingTypeDeliver3Party(shippingType) && <ButtonAssignToDeliver orderDetail={order} />}
      </>
    ),

    [`${ORDER_STATUS.DELIVERING}`]: (
      <>
        {canOrderCancelAfterPayView && <ButtonCancelOrder items={items} handle={onCancelOrder} />}
        <ButtonEditOrder handle={onEditOrder} />
        {canCompleteOrder && <ButtonSuccessfulDelivery orderDetail={order} />}
      </>
    ),
    [`${ORDER_STATUS.COMPLETED}`]: canRecreateCart && <ButtonRecreateOrder handle={onRecreateOrder} />,
    [`${ORDER_STATUS.CANCELED}`]: canRecreateCart && <ButtonRecreateOrder handle={onRecreateOrder} />,
  };

  return (
    <Space style={{ background: "#FFFFFF", width: "100%", display: "flex", justifyContent: "end", padding: "5px" }}>
      {
        renderButtonGroupByStatus[
          `${status === ORDER_STATUS.WAITING_FOR_SHIPPING ? `${status}${shippingStatus ?? ""}` : status}`
        ]
      }
    </Space>
  );
};

export default ButtonGroup;
