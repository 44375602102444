/* eslint-disable react-hooks/exhaustive-deps */

import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Radio, Row, Space, Tooltip, Typography } from "antd";
import Img from "assets/images";
import CustomButton from "components/CustomButton";
import CustomCard from "components/CustomCard";
import CustomDrawer from "components/CustomDrawer";
import CustomModal from "components/CustomModal";
import CustomTextHiddenLine from "components/CustomTextHiddenLine";
import FormInput from "components/FormInput";
import { INPUT_TYPE } from "config/constants";
import { useUpdateProduct } from "hooks/product/product";
import { map } from "lodash";
import { VAT_TYPE } from "pages/App/Product/constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notify, validator } from "utils/helperFuncs";
import CustomModalExportVat from "../CustomModalExportVat";

import "./index.scss";

const inputOptionsPrices = {
  min: 0,
  maxLength: 11,
  style: { width: "100%" },
  addonAfter: <div className="add-on-after">đ</div>,
};

const style = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 12,
};

function DrawerEditProduct({ dataSelected, openDrawerEditProduct, handleCancelDrawerEditProduct, isAvailable }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isVAT, setIsVAT] = useState(true);
  const [formValues, setFormValues] = useState(form.getFieldsValue());
  const { handleUpdateProduct, loading } = useUpdateProduct();
  const [visibleExportVat, setVisibleExportVat] = useState(false);
  const [visible, setVisible] = useState(false);
  const calcValueProduct = map(formValues?.productDiscounts, (productDiscount, index) => {
    const validRegionItems = dataSelected?.stocks?.filter((item) => item?.warehouse?.regionID !== null);

    const minValue = validRegionItems?.reduce((acc, stock) => acc + (stock?.quantity || 0), 0);
    return {
      quantity: productDiscount?.minQuantity,
      discountAmount: productDiscount?.discountAmount,
      smallestValueInStock: minValue || 0,
      smallestValueInPrice: formValues?.productPrices?.price,
    };
  });

  const initForm = {
    productPrices: {
      price: dataSelected?.productPrices?.price,
    },
    vat: dataSelected?.vat || isVAT,
    vatPercent: [-1, 0, 3, 5, 8, 10].includes(dataSelected?.vatPercent) ? undefined : dataSelected?.vatPercent,
    vatValue: dataSelected?.vatValue > -1 ? dataSelected?.vatValue : 0,
    note: dataSelected?.note,
    productDiscounts: dataSelected?.productDiscounts?.map((item) => ({
      minQuantity: item?.minQuantity,
      discountAmount: item?.discountAmount,
    })),
    vatType: dataSelected?.vatType,
    taxableGapVAT: dataSelected?.vatValue > -1,
    taxablePercentVAT:
      dataSelected?.vatPercent !== undefined && [-1, 0, 3, 5, 8, 10].includes(dataSelected?.vatPercent)
        ? dataSelected?.vatPercent
        : "OTHER",
    minOrderQuantity: dataSelected?.minOrderQuantity,
  };

  const handleValuesChange = (changeValue, values) => {
    if (values) {
      setFormValues(values);
    }
  };

  const handleOpenExportVat = async () => {
    await form.validateFields();
    setVisibleExportVat(true);
  };

  const handleCancelExportVat = () => {
    setVisibleExportVat(false);
  };

  const handleSubmitEditProduct = async () => {
    const row = await form.validateFields();

    const price = row?.productPrices?.price;

    const allDiscountsGreaterThanPrice = row?.productDiscounts?.every((discount) => discount.discountAmount > price);
    try {
      if (!allDiscountsGreaterThanPrice || !row?.productDiscounts?.length) {
        await handleUpdateProduct({
          productID: dataSelected?.id,
          vat: row.vat,
          vatValue: row.taxableGapVAT ? row.vatValue : -1,
          vatPercent: [-1, 0, 3, 5, 8, 10].includes(row?.taxablePercentVAT) ? row?.taxablePercentVAT : row?.vatPercent,
          prices: row.productPrices,
          note: row.note,
          productDiscounts: row?.productDiscounts?.every((discount) => discount.discountAmount)
            ? row?.productDiscounts
            : [],
          sellingStatus: isAvailable,
          vatType: row?.vatType,
          minOrderQuantity: row?.minOrderQuantity,
        });
        notify.success({
          message: t("product.validateMessage.updateSuccess"),
        });
        setVisible(false);
        setVisibleExportVat(false);
        handleCancelDrawerEditProduct();
      }
    } catch (errInfo) {
      notify.error({
        message: t("product.validateMessage.updateError"),
        description: errInfo?.message,
      });
    }
  };

  const handleChangeVatType = (e) => {
    if (e.target.value === VAT_TYPE.NOT_REQUIRED) {
      const formData = form.getFieldsValue();
      form.setFieldsValue({ ...formData, taxableGapVAT: false, taxablePercentVAT: -1 });
      setFormValues({ ...formData, taxableGapVAT: false, taxablePercentVAT: -1 });
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...initForm });
    setIsVAT(dataSelected?.vat);
    setFormValues(form.getFieldsValue());
  }, [dataSelected]);

  const optionTaxable = [
    { value: false, label: t("v2.product.drawer_edit_product.not_taxable") },
    { value: true, label: t("v2.product.drawer_edit_product.taxable") },
  ];

  const optionVatPercent = [
    { value: -1, label: t("v2.product.drawer_edit_product.not_taxable") },
    { value: 0, label: "0%" },
    { value: 3, label: "3%" },
    { value: 5, label: "5%" },
    { value: 8, label: "8%" },
    { value: 10, label: "10%" },
    { value: "OTHER", label: "Khác" },
  ];

  const optionVatType = [
    {
      value: VAT_TYPE.REQUIRED,
      label: t("v2.product.drawer_edit_product.export_vat"),
    },
    {
      value: VAT_TYPE.NOT_REQUIRED,
      label: t("v2.product.drawer_edit_product.not_export_vat"),
    },
    {
      value: VAT_TYPE.OPTIONAL,
      label: t("v2.product.drawer_edit_product.optional"),
    },
  ];

  return (
    <CustomDrawer
      visible={openDrawerEditProduct}
      title={t("product.edit")}
      onOk={() => {
        if (
          (dataSelected?.stocks === null ||
            (dataSelected?.stocks && dataSelected?.stocks[0]?.quantityRealStockHasVat === 0)) &&
          formValues?.vat === true
        ) {
          handleOpenExportVat();
        } else {
          if (
            formValues?.vatType === VAT_TYPE.REQUIRED &&
            (dataSelected?.stocks === null ||
              (dataSelected?.stocks && dataSelected?.stocks[0]?.quantityRealStockHasVat === 0))
          ) {
            setVisible(true);
          } else {
            handleSubmitEditProduct();
          }
        }
      }}
      onClose={() => handleCancelDrawerEditProduct()}
      loading={loading}
      bodyStyle={{ background: "#F7F8FB" }}
      width={700}
      buttonCancel={
        <CustomModal
          customComponent={<CustomButton isSecondary title={t("common.cancel")} />}
          icon={Img.QuestionIcon}
          message={t("product.pricePolicy.messageCloseDrawer")}
          footer={false}
          onOke={() => handleCancelDrawerEditProduct()}
        />
      }
      headerSticky={
        <Card>
          <Row style={{ flexWrap: "nowrap", alignItems: "center" }}>
            <Col span={5}>
              <Typography.Text strong>{t("common.product")}</Typography.Text>
            </Col>
            <Col>
              <Space direction="vertical" size={[0]}>
                <CustomTextHiddenLine
                  isLink={true}
                  strong={"strong"}
                  text={dataSelected?.code}
                  link={`product/detail/${dataSelected?.id}`}
                />
                <CustomTextHiddenLine text={dataSelected?.name} />
              </Space>
            </Col>
          </Row>
        </Card>
      }
      content={
        <Form
          initialValues={initForm}
          className="custom-drawer-edit-product"
          form={form}
          colon={false}
          layout="vertical"
          onValuesChange={handleValuesChange}
        >
          <CustomModalExportVat
            handleOpenExportVat={handleOpenExportVat}
            handleCancelExportVat={handleCancelExportVat}
            visibleExportVat={visibleExportVat}
            handleSubmitEditProduct={handleSubmitEditProduct}
            loading={loading}
            productCode={dataSelected?.code}
          />
          <CustomModal
            visible={visible}
            icon={Img.QuestionIcon}
            message={
              <>
                <div>
                  Mã sản phẩm <Typography.Link>{dataSelected?.code}</Typography.Link> có{" "}
                  <Typography.Link>tồn VAT = 0</Typography.Link>.
                </div>
                <div>Bạn có chắn chắn xuất VAT cho mã sản phẩm này không?</div>
              </>
            }
            footer={false}
            title={<Typography.Text strong>Xác nhận xuất VAT</Typography.Text>}
            onOke={() => handleSubmitEditProduct()}
            onCancel={() => setVisible(false)}
          />

          <div style={style}>
            <div className="edit_general_info">
              <CustomCard
                title={"Cập nhật thông tin chung"}
                content={
                  <Row gutter={[24, 0]} className="price-update-container">
                    <Col span={12}>
                      <FormInput
                        type={INPUT_TYPE.CURRENCY_INPUT}
                        formItemOptions={{
                          name: ["productPrices", "price"],
                          rules: [
                            {
                              required: true,
                              message: "Vui lòng không được để trống trường này!",
                            },
                            {
                              type: "number",
                              min: 1,
                              message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                            },
                            validator({
                              type: "number",
                            }),
                          ],
                          label: <Typography.Text strong>{"Giá chưa VAT"}</Typography.Text>,
                        }}
                        inputOptions={{
                          ...inputOptionsPrices,
                          placeholder: "Nhập giá chưa bao gồm thuế VAT",
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <FormInput
                        type={INPUT_TYPE.NUMBER}
                        formItemOptions={{
                          name: "minOrderQuantity",
                          required: true,
                          rules: [
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (value === null || value === undefined || value === "") {
                                  return Promise.reject(new Error("Vui lòng nhập mức MOQ"));
                                }
                                if (!/^\d+$/.test(value) || Number(value) < 1) {
                                  return Promise.reject(new Error("Vui lòng nhập mức MOQ là số nguyên dương"));
                                }
                                return Promise.resolve();
                              },
                            }),
                          ],
                          label: <Typography.Text strong>Mức MOQ</Typography.Text>,
                        }}
                      />
                    </Col>
                  </Row>
                }
              />
            </div>
            <div className="vatInfo">
              <CustomCard
                title={t("product.pricePolicy.vatInfo")}
                content={
                  <div>
                    <Row gutter={[24, 0]} className="vat-info-container">
                      <Col span={24}>
                        <Row className="radio-group-type-group">
                          <Typography.Text strong className="title">
                            {t("v2.product.drawer_edit_product.product_export_vat")}
                            <span className="icon-required"></span>
                          </Typography.Text>
                          <Form.Item
                            name="vatType"
                            rules={[
                              {
                                required: true,
                                message: t("v2.product.drawer_edit_product.please_select_product_vat_exported"),
                              },
                            ]}
                          >
                            <Radio.Group onChange={handleChangeVatType}>
                              {optionVatType?.map((item) => {
                                return <Radio.Button value={item?.value}>{item?.label}</Radio.Button>;
                              })}
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[24, 0]} className="vat-info-container">
                      <Col span={12}>
                        <Row className="vat-value-group">
                          <FormInput
                            type={INPUT_TYPE.SELECT}
                            formItemOptions={{
                              style: { minWidth: "130px" },
                              rules: [
                                {
                                  required: true,
                                  message: t("common.messageRequiredType", {
                                    message: t("product.tableTitle.vatValue"),
                                  }),
                                },
                              ],
                              name: "taxablePercentVAT",
                              label: (
                                <div>
                                  <Typography.Text strong>Loại % VAT *</Typography.Text>{" "}
                                  <Tooltip
                                    title={
                                      <>
                                        <span>{t("product.productForm.percentVATTooltipTop")}</span>
                                        <br />
                                        <span>{t("product.productForm.percentVATTooltipBottom")}</span>
                                      </>
                                    }
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </div>
                              ),
                            }}
                            inputOptions={{
                              options: optionVatPercent,
                              disabled: !(formValues?.vatType !== VAT_TYPE.NOT_REQUIRED),
                              defaultValue: -1,
                            }}
                          />
                          <FormInput
                            type={INPUT_TYPE.NUMBER}
                            key={formValues?.taxablePercentVAT}
                            formItemOptions={{
                              rules: [
                                {
                                  required: ![-1, 0, 3, 5, 8, 10].includes(formValues?.taxablePercentVAT),
                                  message: t("common.messageRequiredType", {
                                    message: t("product.productForm.percentVAT"),
                                  }),
                                },
                              ],
                              name: "vatPercent",
                              className: "vat",
                            }}
                            inputOptions={{
                              min: 0,
                              max: 100,
                              addonAfter: <div className="add-on-after">%</div>,
                              placeholder: t("product.productForm.percentVATPlaceholder"),
                              disabled: [-1, 0, 3, 5, 8, 10].includes(formValues?.taxablePercentVAT),
                              maxLength: 3,
                            }}
                          />
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row className="vat-value-group">
                          <FormInput
                            type={INPUT_TYPE.SELECT}
                            formItemOptions={{
                              rules: [
                                {
                                  required: true,
                                  message: t("common.messageRequiredType", {
                                    message: t("product.tableTitle.vatValue"),
                                  }),
                                },
                              ],
                              name: "taxableGapVAT",
                              label: (
                                <div>
                                  <Typography.Text strong>{t("product.tableTitle.vatValue")}</Typography.Text>{" "}
                                  <Tooltip
                                    title={
                                      <>
                                        <span>{t("product.productForm.VATDifferenceTooltipTop")}</span>
                                        <br />
                                        <span>{t("product.productForm.VATDifferenceTooltipBottom")}</span>
                                      </>
                                    }
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </div>
                              ),
                            }}
                            inputOptions={{
                              options: optionTaxable,
                              disabled: !(formValues?.vatType !== VAT_TYPE.NOT_REQUIRED),
                              defaultValue: true,
                            }}
                          />
                          <FormInput
                            type={INPUT_TYPE.CURRENCY_INPUT}
                            formItemOptions={{
                              rules: [
                                {
                                  required: formValues?.taxableGapVAT,
                                  message: t("common.messageRequiredType", {
                                    message: t("product.tableTitle.vatValue"),
                                  }),
                                },
                              ],
                              name: "vatValue",
                            }}
                            inputOptions={{
                              ...inputOptionsPrices,
                              placeholder: t("product.productForm.VATDifferencePlaceholder"),
                              disabled: !formValues?.taxableGapVAT,
                            }}
                          />
                        </Row>
                      </Col>
                    </Row>
                  </div>
                }
              />
            </div>
            <div className="pricePolicyUpdate">
              <CustomCard
                title={t("product.pricePolicy.pricePolicyUpdate")}
                content={
                  <Form.List name="productDiscounts">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => {
                          const isValidateHelpQty =
                            calcValueProduct?.[index]?.quantity > calcValueProduct?.[index]?.smallestValueInStock ||
                            calcValueProduct?.[index]?.quantity < formValues?.minOrderQuantity;

                          const isValidateHelpPrice =
                            calcValueProduct?.[index]?.discountAmount > calcValueProduct?.[index]?.smallestValueInPrice;

                          return (
                            <div key={field?.key}>
                              <Space style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                  <Typography.Text strong>
                                    {t("product.pricePolicy.level")} {index + 1}
                                  </Typography.Text>
                                </div>
                                <div>
                                  <Space>
                                    <Typography.Text>{t("product.pricePolicy.buyFrom")}</Typography.Text>
                                    <FormInput
                                      type={INPUT_TYPE.NUMBER}
                                      {...{
                                        formItemOptions: {
                                          name: [field?.name, "minQuantity"],
                                          validateStatus: (() => {
                                            const minOrderQty = formValues?.minOrderQuantity;
                                            const quantity = calcValueProduct?.[index]?.quantity;
                                            if (quantity < minOrderQty) {
                                              return "error";
                                            }
                                            if (isValidateHelpQty) {
                                              return "warning";
                                            }
                                            return undefined;
                                          })(),
                                          help: (() => {
                                            const minOrderQty = formValues?.minOrderQuantity;
                                            const quantity = calcValueProduct?.[index]?.quantity;
                                            if (quantity < minOrderQty) {
                                              return `Vui lòng nhập số lượng ≥ mức MOQ`;
                                            }
                                            if (isValidateHelpQty) {
                                              return t("cart.lackOfStock");
                                            }
                                            return undefined;
                                          })(),
                                          rules: [
                                            {
                                              required: true,
                                              message: t("campaign.combo.errorMessage.qty"),
                                            },

                                            validator({
                                              type: "number",
                                            }),
                                          ],
                                        },
                                        inputOptions: {
                                          placeholder: t("campaign.combo.placeholder.type"),
                                          className: "input-number-qty",
                                          maxLength: 10,
                                        },
                                      }}
                                    />

                                    <Typography.Text>{t("product.pricePolicy.product")}</Typography.Text>
                                    <FormInput
                                      {...{
                                        type: INPUT_TYPE.CURRENCY_INPUT,
                                        formItemOptions: {
                                          name: [field?.name, "discountAmount"],
                                          validateStatus: isValidateHelpPrice ? "error" : undefined,
                                          help: isValidateHelpPrice
                                            ? t("product.pricePolicy.invalidDiscount")
                                            : undefined,
                                          rules: [
                                            {
                                              required: true,
                                              message: t("campaign.combo.errorMessage.typeDiscount"),
                                            },
                                            {
                                              type: "number",
                                              min: 1,
                                              message: t("campaign.combo.errorMessage.valueMustBeThan0"),
                                            },
                                            validator({
                                              type: "number",
                                            }),
                                          ],
                                        },
                                        inputOptions: {
                                          className: "input-number",
                                          placeholder: t("campaign.combo.placeholder.type"),
                                        },
                                      }}
                                    />
                                    <Typography.Text>{t("product.pricePolicy.onProduct")}</Typography.Text>
                                  </Space>
                                </div>
                                <div>
                                  <DeleteOutlined onClick={() => remove(field?.name)} />
                                </div>
                              </Space>
                              <Divider style={{ margin: "12px 0" }} />
                            </div>
                          );
                        })}
                        {fields?.length < 4 && (
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            {t("product.pricePolicy.additionalIncentives")}
                          </Button>
                        )}
                      </>
                    )}
                  </Form.List>
                }
              />
            </div>
            <div className="note">
              <CustomCard
                title={t("product.tableTitle.note")}
                content={
                  <FormInput
                    type={INPUT_TYPE.TEXT_AREA}
                    formItemOptions={{ name: "note" }}
                    inputOptions={{
                      placeholder: t("product.tableTitle.note"),
                      autoSize: { minRows: 2 },
                    }}
                  />
                }
              />
            </div>
          </div>
        </Form>
      }
    />
  );
}

export default DrawerEditProduct;
